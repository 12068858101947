<template>
<div class="headerPrice" :class="{stickyStyle: sticky  }">
    <div class="container">
        <div class="head-block">
            <div class="back-button">
                <button class="head-button" @click="back" v-if="showNavigation && Global.motorVehicle.flagRetrievePolicy === false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M10.0781 12.6668L5.37486 8.00016L10.0781 3.3335" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{ $t('motorVehicle.back') }}
                </button>
            </div>
            <!-- <div class="optiNUmber" v-if="opportunityNumber">{{ $t('motorVehicle.quotationNo') }} {{ opportunityNumber }}</div>
            <div class="optiNUmber" v-else></div> -->
            <!-- <div class="instalment" v-if="showNavigation">
                <button class="head-button" data-selenium-name="btn-head-installment-fix" @click="Global.motorVehicle.isShowInstallmentModal = true;">
                    {{ $t('motorVehicle.installmentOption') }}
                    <svg fill="#A8CEF1" height="10" width="10" viewBox="0 0 24 24" role="img" style="transform: rotate(-90deg);">
                        <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                    </svg>
                </button>
            </div> -->
        </div>
        <div class="main-price" v-if="paymentType.includes('Month')">
            <div class="optiNUmber" >{{ $t('motorVehicle.premi') }}</div>
            <div class="d-flex justify-content-center">
                <span class="prc prc-cur">{{ $t('motorVehicle.currencySymbol') }}</span>
                <span class="prc prc-sum" :class="{'square-animation': triggerPrice}"> {{ price }} </span>
                <span class="prc prc-time-mth"> x 6 </span>
                <span class="prc prc-freq-mth">{{ $t('motorVehicle.month') }} </span>
            </div>
        </div>
        <div class="main-price" v-else>
            <div class="optiNUmber" >{{ $t('motorVehicle.premi') }}</div>
            <div class="d-flex justify-content-center">
                <span class="prc prc-cur">{{ $t('motorVehicle.currencySymbol') }}</span>
                <span class="prc prc-sum" :class="{'square-animation': triggerPrice}"> {{ price }} </span>
                <span class="prc prc-time-year"> /</span>
                <span class="prc prc-freq-year">{{ $t('motorVehicle.year') }} </span>
            </div>
        </div>    

        <div class="ad-word" v-if="isGetReward">
            <span class="d-flex bonus-text">{{ $t('motorVehicle.rewardsInfoHeader') }} <strong class="worth" :class="{'square-animation': trigger}">{{ $t('motorVehicle.rewardsInfoHeader2') }} {{ $t('motorVehicle.currencySymbol') }}{{numberFormat(rewardNum)}}</strong>
                <div class="tooltip-container" @click="infoReward(rewardNum)">
                    <div class="img-tooltip"><img src="../../../../public/images/motor-vehicle/information-circle.svg" alt=""></div>
                </div>
            </span>
        </div>
    </div>
</div>
</template>
<script>
import Global from "@/store/global.js";
import { numberLiteralTypeAnnotation } from "@babel/types";
import Tooltip from './Tooltip.vue';
export default {
    name: "HeaderPrice",
    props: {
        sticky: {
            type: Boolean,
            default: true
        },
        triggerAnimation:{
            type: Boolean,
            default: false
        },
        price: {
            type: [Number, String],
            default: 0,
        },
        rewardNum: {
            type: Number,
            default: 0,
        },
        paymentType: {
            type: String,
            default: "Monthly"
        },
        opportunityNumber: {
            type: String,
            default: null
        },
        isGetReward:{
            type: Boolean,
            default : false
        },
        animateSection: {
            type: String,
            default: null
        }

    },
    components: { 
        Tooltip 
    },
    data() {
        return {
            Global,
            showNavigation: false,
            triggerPrice: false,
            trigger: false,
            showInstallment: true,
        }
    },
    created() {
        if(this.sticky) {
            window.addEventListener('scroll', this.onScrollNavbar);
        }
    },
    methods: {
        numberFormat(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        back() {
            this.$router.go(-1);
        },
        infoReward(value){
            this.$emit("openInfoReward", value);
        },
        onScrollNavbar() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition > 100) {
                this.showNavigation = true;
                setTimeout(()=> {
                    this.showInstallment = false;
                },500);
            } 
            else {
                this.showNavigation = false;
                // setTimeout(()=> {
                //     this.showInstallment = true;
                // },500);
            } 

        }
    },
    computed: {
        textTooltip() {
            return this.$i18n.t('motorVehicle.tooltipReward');
        }
    },
    watch: {
        triggerAnimation(value) {
            if (value) {
                if (this.animateSection == 'reward') {
                    this.trigger = true
                    setTimeout(()=> {
                        this.trigger = false
                    },500);
                }else if(this.animateSection == 'price'){
                    this.triggerPrice = true
                    setTimeout(()=> {
                        this.triggerPrice = false
                    },1000);
                }else if(this.animateSection == 'sumInsured'){
                    this.triggerPrice = true
                    this.trigger = true
                    setTimeout(()=> {
                        this.triggerPrice = false
                        this.trigger = false
                    },500);
                }
            }
        }
    }
}
</script>
<style scoped>
.headerPrice {
    background: linear-gradient(180deg, #015CAF 0%, #003D75 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0px 2px 42px rgba(0, 58, 109, 0.48));
    border-radius: 0px 0px 8px 8px;
    top: 0;
    position: fixed;
    width: 100%;
    min-height: 80px;
    display: block;
    z-index: 999;
    padding: 15px 0px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transition: opacity 0.5;
}
.headerPrice .head-block {
    position: absolute;
    width: 70%;
    z-index: 100;
    top: 12px;
}
.headerPrice .head-block .instalment {
    width: 25%;
    text-align: right;
}
.headerPrice .main-price .optiNUmber {
    width: 100%;
    text-align: center;
    color: #C4EAFF;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
}
.worth{
    margin-left: 4px;
}
.prc-sum{
    font-weight: 800 !important;
    line-height: 34px;
    margin-left: 10px;
    font-size: 32px;
    font-weight: 500;
    color:#E5F6FF !important;
}
.square-animation {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes rubberBand {
0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
}
40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
}
50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
}
65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
}
75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
}
100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
}
@keyframes rubberBand {
0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
}
40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
}
50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
}
65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
}
75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
}
100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
}
.tooltip-container { 
    position: relative;
    display: inherit;
    cursor: pointer;
}
.tooltip-container .img-tooltip:hover + .tooltip{
    opacity: 1;
}
.tooltip { 
    color: #ffffff;
    text-align: left;
    padding: 5px!important;
    border-radius: 4px;
    max-width: 250px;
    width: 200px;
    right: -200px;
    top: -84px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    z-index: 1;
    background: #0E5DA7;
}
.text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #0E5DA7 transparent transparent transparent
}
.img-tooltip {
    margin-left: 4px;
}
.prc-cur {
    line-height: 34px;
    font-size: 16px;
    color: #C4EAFF;
}
.prc-time {
    margin-left: -12px;
    line-height: 34px;
}
.prc-freq-mth{
    font-weight: 400;
    line-height: 34px;
    margin-left: 6px;
    font-weight: 300;
    font-size: 16px;
    color: #C4EAFF;
}
.prc-freq-year{
    font-weight: 400;
    line-height: 34px;
    margin-left: 6px;
    font-weight: 300;
    font-size: 16px;
    color: #C4EAFF;
}
.prc-time-mth {
    font-weight: 400;
    line-height: 34px;
    font-size: 16px;
    margin-left: 6px;
    color: #C4EAFF;
}
.prc-time-year{
    font-weight: 400;
    line-height: 34px;
    font-size: 16px;
    margin-left: 6px;
}
@media screen and (max-width: 480px) {
    .optionForDealerWs {
        font-size: 16px;
    }
    .tooltip {
        right: 0!important;
        left: auto;
    }
    .text::after {
        display: none;
    }
}
.headerPrice .head-block button{
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;   
    color: #FFF;
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
}
.headerPrice .main-price {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-top: 8px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.headerPrice .ad-word {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.headerPrice .ad-word i {
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 1px 6px;
    font-size: 10px;
}
.headerPrice .installment-bottom {
    text-align: center;
    margin-bottom: 13px;
    margin-top: 5px;
}

.headerPrice .installment-bottom .op-installment {
    background: transparent;
    font-size: 12px;
    color: #A8CEF1;
    border: 1px solid #A8CEF1;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
    border-radius: 11px;
}
.bonus-text{
    align-items:center;
}
.stickyStyle {
    position:sticky!important;
}
@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    20% {opacity: 0.2;}
    40% {opacity: 0.4;}
    60% {opacity: 0.6;}
    80% {opacity: 0.8;}
    100% {opacity: 1;}
}
@keyframes fadeIn {
    0% {opacity: 0;}
    20% {opacity: 0.2;}
    40% {opacity: 0.4;}
    60% {opacity: 0.6;}
    80% {opacity: 0.8;}
    100% {opacity: 1;}
}
@media screen and (max-width: 480px) {

    .headerPrice .main-price{
        margin-top: 18px;
    }
    .bonus-text{
        align-items:center;
    }
    .headerPrice .ad-word {
        font-size: 12px;
    }
    /* .headerPrice {
        padding: 7px 0px;
    }
    .headerPrice .main-price {
        font-size: 32px;
    } 
    .headerPrice .main-price .prc {
        font-size: 28px;
    } */
    /* .headerPrice .head-block button {
        font-size: 12px;
    } */
    /* .headerPrice .head-block .back-button {
        width: 30%;
    }
    .headerPrice .head-block .instalment {
        width: 30%;
    }
    .headerPrice .head-block .optiNUmber {
        width: 37%;
        font-size: 12px;
    } */
}
</style>